import React from "react";

import SocialLinks from './SocialLinks';

function SideBar() {

    return (
        <div className="tb-sidebar">
            <h2>Side Bar</h2>
            <h5>Important information:</h5>
            <p>Some important text in culpa qui officia deserunt mollit anim..</p>
            <h3>More Text</h3>
            <p>Lorem ipsum dolor sit ame.</p>
        </div>
    );
}

export default SideBar;