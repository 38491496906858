declare var require: any
var React = require('react');

function ReportByPeriod() {

    return (
        <h1>Report by Period</h1>
    );
}

export default ReportByPeriod;