declare var require: any
var React = require('react');

function BudgetView() {

    return (
        <h1>Budget View</h1>
    );
}

export default BudgetView;