declare var require: any
var React = require('react');

function ReportByAccount() {

    return (
        <h1>Report by Account</h1>
    );
}

export default ReportByAccount;