declare var require: any
var React = require('react');

function TransactionEntry() {

    return (
        <h1>Manually Enter Transactions</h1>
    );
}

export default TransactionEntry;